import React from "react";

export const Hatebu: React.VFC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="4585.332 133.411 14 12">
    <g id="group1" fill="currentColor">
      <path
        id="rectangle1"
        className="cls-1"
        d="M4596.376 133.411h2.761v7.987h-2.761z"
      />
      <path
        id="path1"
        className="cls-1"
        d="M482.478 55.274a2.935 2.935 0 0 0-1.945-.9 3.262 3.262 0 0 0 1.633-.9 2.356 2.356 0 0 0 .506-1.613 2.729 2.729 0 0 0-.35-1.416 2.608 2.608 0 0 0-.972-.984 4.276 4.276 0 0 0-1.361-.433 21.785 21.785 0 0 0-2.761-.118h-3.228v11.833h3.306a24.48 24.48 0 0 0 2.878-.118 3.859 3.859 0 0 0 1.478-.472 2.783 2.783 0 0 0 1.128-1.1 3.3 3.3 0 0 0 .389-1.692 3 3 0 0 0-.701-2.087zm-5.522-3.738h.7a3.031 3.031 0 0 1 1.595.275 1.1 1.1 0 0 1 .428.944.978.978 0 0 1-.428.9 3.7 3.7 0 0 1-1.633.275h-.622v-2.4zm2.722 6.807a3.22 3.22 0 0 1-1.594.275h-1.128v-2.6h1.167a2.951 2.951 0 0 1 1.594.315 1.234 1.234 0 0 1 .428 1.062 1.034 1.034 0 0 1-.467.948z"
        transform="translate(4585.332 133.411) translate(-474 -48.861)"
      />
      <ellipse
        id="circle1"
        className="cls-1"
        cx="1.594"
        cy="1.613"
        rx="1.594"
        ry="1.613"
        transform="translate(4585.332 133.411) translate(10.811 8.774)"
      />
    </g>
  </svg>
);
